<form class="company-address-form">
	<mat-form-field
		class="company-address-form-field-element"
		appearance="outline"
	>
		<mat-label>Enter the Company address</mat-label>
		<input
			matInput
			#input
			placeholder="Ex. 123 Street, City, State. Zip Code"
			(input)="onInput($event)"
		/>
	</mat-form-field>
</form>
