export const itemsDuringWarmUpB = [
	"School Bus Warning Lights",
	"Service Door Operation",
	"Emergency Exits",
	"Emergency Exits Buzzers",
	"Stop Arm",
	"Crossing Gate",
	"Exhaust System",
	"Fluid Leaks",
	"Steering Wheel Free Play",
	"Steering Column",
	"Drivers Seatbelt",
	"Passanger SeatBelt",
	"Miscellaneous",
	"HANDICAPPED",
	"(If Applicable)",
	"Power Lift",
	"Lift Door",
	"Buzzer",
	"Interlock",
	"Identification",
	"Light",
	"Fluid Leaks",
];
