export const itemsBeforeOperating = [
	"Bus Interior Damage",
	"Bus Interior Cleanliness",
	"Fire Extinguisher",
	"First Aid Kit (If Applicable)",
	"Portable Warning Devices",
	"Wrecking Bar (If Applicable)",
	"Seat Belt Cutter",
	"Seats",
	"Left Front Tire",
	"Right Front Tire",
	"Left Rear Outside Tire",
	"Left Rear Inside Tire",
	"Right Rear Outside Tire",
	"Right Rear Inside Tire",
	"Wheels / Rims",
	"Lug Nuts",
	"Credentials",
	"Bus Exterior Damage",
	"MILEAGE BEGINNING",
	"",
	"MILEAGE ENDING",
	"",
];
