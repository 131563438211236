import { Component, EventEmitter, Output } from "@angular/core";

@Component({
	selector: "app-company-address-input",
	templateUrl: "./company-address-input.component.html",
	styleUrls: ["./company-address-input.component.scss"],
})
export class CompanyAddressInputComponent {
	@Output() emitCompanyAddress = new EventEmitter();

	public onInput(event: InputEvent) {
		this.emitCompanyAddress.emit((event.currentTarget as HTMLInputElement).value);
	}
}
