const idFields = {
	assets: "assetId",
	divisions: "divisionId",
	inspectors: "inspectorId",
} as const;

type DisplayValueFields = typeof idFields;
type DisplayValueFieldsKey = keyof DisplayValueFields;

export type SingleRecord = {
	[Key in DisplayValueFieldsKey]: Record<DisplayValueFields[Key][number], string>;
}[DisplayValueFieldsKey];

export const getId = (record: SingleRecord, tableName: keyof typeof idFields): string => record[idFields[tableName]];
