import { TableRecord } from "../get-table-filter.component";

/**
 * Compare two TableRecords for equality
 */
export function tableRecordsEqual(record1: TableRecord, record2: TableRecord) {
	let equal = record1.fields.length === record2.fields.length;

	for (let i = 0; i < record1.fields.length; i++) {
		equal =
			equal &&
			record1.fields[i].name === record2.fields[i].name &&
			record1.fields[i].value === record2.fields[i].value;

		if (!equal) {
			break;
		}
	}

	return equal;
}
