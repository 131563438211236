import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IUser } from "@zonar-ui/auth/lib/models/user.model";
import { from, Observable } from "rxjs";
import { HydratedUserProfile } from "src/app/models/core-api.models";
import { GlobalApiCallsService } from "src/app/services/global-api-calls.service";
import { RequestService } from "src/app/services/request/request.service";
import { ServiceUtilsService } from "src/app/services/service-utils.service";
import { environment } from "src/environments/environment";
import { RepairRequest, RepairResolutionsResponseInner, RepairResponse } from "../../../models/openAPIAliases";

@Injectable({
	providedIn: "root",
})
export class RepairService {
	public repairEndpoint = "/defects";
	public resolutionEndpoint = "/repair-resolutions";
	public userEndpoint = "/users";
	public userProfileEndpoint = "/userprofiles";

	constructor(
		public requestService: RequestService,
		public serviceUtils: ServiceUtilsService,
		public globalApiCallsService: GlobalApiCallsService,
	) {}

	public getMechanicUsersFromProfiles(companyId: string): Observable<Array<IUser>> {
		return from(
			Promise.all([
				this.getMechanicProfiles(
					companyId,
					environment.environmentConstants.APP_ROLE_REPAIR_DEFECT_MECHANIC,
					environment.environmentConstants.APP_ENDPOINT_CORE,
				).toPromise(),
				this.getMechanicProfiles(
					companyId,
					environment.environmentConstants.APP_ROLE_ASSIGN_MECHANIC,
					environment.environmentConstants.APP_ENDPOINT_CORE,
				).toPromise(),
			])
				.then(([mechanicProfiles, supervisorMechanicProfiles]) => {
					const map = new Map();
					[...mechanicProfiles, ...supervisorMechanicProfiles].forEach(profile => {
						map.set(profile.userId, { ...map.get(profile.userId), profile });
					});
					const uniqueProfiles = Array.from(map.values()).slice(0, 1000);
					return uniqueProfiles.map(({ profile }) => profile);
				})
				.then(mechanicProfiles =>
					Promise.all(
						mechanicProfiles.map(async profile => {
							const user = await this.requestService
								.get<IUser>({
									url: `${environment.environmentConstants.APP_ENDPOINT_CORE}${this.userEndpoint}/${profile.userId}`,
								})
								.toPromise();

							return user;
						}),
					),
				),
		);
	}

	public getMechanicProfiles(companyId: string, roleId: string, endpoint: string): Observable<HydratedUserProfile[]> {
		const url = new URL(`${endpoint}${this.userProfileEndpoint}`);
		url.searchParams.set("companyId", companyId);
		url.searchParams.set("roleId", roleId);
		return this.serviceUtils.getAllPages(url.toString(), "mechanics profiles", 100, 1, "per_page");
	}

	public getMechanicUsersFromPolicies(companyId: string): Observable<Array<IUser>> {
		return from(
			this.globalApiCallsService
				.getPoliciesFromCompany(companyId, environment.environmentConstants.APP_ENDPOINT_CORE)
				.toPromise()
				.then(policies => {
					return policies.filter(policy =>
						policy.grants.filter(grant =>
							grant.roles.some(
								role =>
									environment.environmentConstants.APP_ROLE_REPAIR_DEFECT_MECHANIC === role.id ||
									environment.environmentConstants.APP_ROLE_ASSIGN_MECHANIC === role.id,
							),
						),
					);
				})
				.then(mechanicPolicies =>
					Promise.all(
						mechanicPolicies.map(async policy => {
							const groups = await this.globalApiCallsService
								.getGroupsFromPolicy(policy.id, environment.environmentConstants.APP_ENDPOINT_CORE)
								.toPromise();
							return groups;
						}),
					),
				)
				.then(mechanicGroups => {
					const mechanicGroupUsers: Array<Array<IUser>> = mechanicGroups.map(groups =>
						groups.reduce((output, group) => {
							return [...output, ...group.members];
						}, []),
					);
					return mechanicGroupUsers;
				})
				.then(mechanicGroupUsers => mechanicGroupUsers.flat())
				.then(users =>
					users.filter((user, index, self) => {
						return index === self.findIndex(temp => temp.id === user.id);
					}),
				),
		);
	}

	public getResolution(companyId: string, endpoint: string): Observable<RepairResolutionsResponseInner[]> {
		return this.requestService.get<RepairResolutionsResponseInner[]>({
			url: `${endpoint}${this.resolutionEndpoint}?companyId=${companyId}`,
			httpOptions: {},
		});
	}

	public postRepairStatus(
		repair: RepairRequest,
		defectId: string,
		endpoint: string,
	): Observable<HttpResponse<RepairResponse>> {
		return this.requestService.post<HttpResponse<RepairResponse>>({
			url: `${endpoint}${this.repairEndpoint}/${defectId}/repairs`,
			httpOptions: { observe: "response" },
			data: repair,
		});
	}
}
