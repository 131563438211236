<div class="njmvc-container">
	<div class="njmvc-report-header">
		<h1 class="title">NJMVC DRIVERS VEHICLE INSPECTION REPORT</h1>
		<h2 class="apendix">Appendix B</h2>
	</div>

	<!-- INSPECTION INFO PART 1 -->
	<table class="inspection-info-table-part-1">
		<thead>
			<tr>
				<th scope="col">DATE OF INSPECTION</th>
				<th scope="col">TIME</th>
				<th scope="col">DATE OPERATOR NOTIFIED</th>
				<th scope="col">TIME</th>
				<th scope="col">OPERATOR / DESIGNEE SIGNATURE</th>
				<th scope="col">FLEET UNIT NUMBER</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td>{{ inspectionData.inspectionDate }}</td>
				<td>{{ inspectionData.inspectionTime }}</td>
				<td>{{ inspectionData.inspectionDate }}</td>
				<td>{{ inspectionData.inspectionTime }}</td>
				<td>{{ inspectionData.driverNameSigned }}</td>
				<td>{{ inspectionData.fleetUnitNumber }}</td>
			</tr>
		</tbody>
		<tbody></tbody>
	</table>
	<!-- INSPECTION INFO PART 2 -->
	<table class="inspection-info-table-part-2">
		<thead>
			<tr class="inspection-report-row">
				<td class="operator">
					<span class="floating-header">OPERATOR</span>
					{{ inspectionData.operator }}
				</td>
				<td class="driver-name">
					<span class="floating-header">DRIVER NAME (Print or Type)</span>
					{{ inspectionData.driverName }}
				</td>
				<td class="license-plate-number">
					<span class="floating-header">LICENSE PLATE NUMBER</span>
					{{ inspectionData.licensePlateNumber }}
				</td>
			</tr>
			<tr class="inspection-report-row">
				<td class="address">
					<span class="floating-header">ADDRESS</span>
					{{ inspectionData.address }}
				</td>
				<td class="driver-name-signed">
					<span class="floating-header">DRIVER NAME SIGNED</span>
					{{ inspectionData.driverNameSigned }}
				</td>
				<td class="vehicle-type">
					<span class="floating-header">VEHICLE TYPE (Circle One)</span>
					<div class="vehicle-type-options">
						<span
							class="vehicle-type-option"
							[ngClass]="{ selected: vehicleType === 'A' }"
						>
							<strong>A</strong>
						</span>
						<span
							class="vehicle-type-option"
							[ngClass]="{ selected: vehicleType === 'B' }"
						>
							<strong>B</strong>
						</span>
						<span
							class="vehicle-type-option"
							[ngClass]="{ selected: vehicleType === 'C' }"
						>
							<strong>C</strong>
						</span>
						<span
							class="vehicle-type-option"
							[ngClass]="{ selected: vehicleType === 'D' }"
						>
							<strong>D</strong>
						</span>
						<span
							class="vehicle-type-option-sv"
							[ngClass]="{ selected: vehicleType === 'SV' }"
						>
							<strong>SV</strong>
						</span>
					</div>
				</td>
			</tr>
		</thead>
	</table>

	<!-- VEHICLE COMPONENTS INSPECTED -->
	<table class="vehicle-components-inspected">
		<!-- TABLE CAPTION -->
		<caption>
			<p>
				<strong>√</strong>
				if O.K. or
				<strong>X</strong>
				if defective. If vehicle has any problem that would impair a safe trip, contact the office before moving
				vehicle.
			</p>
			<p>
				<strong><em>ALL DEFECTS MUST BE WRITTEN UP ON A VEHICLE REPAIR REQUEST</em></strong>
			</p>
		</caption>
		<thead>
			<tr>
				<th
					scope="col"
					colspan="12"
					class="vehicle-components-inspected-title"
				>
					VEHICLE COMPONENTS INSPECTED
				</th>
			</tr>
			<tr>
				<th
					class="repair"
					scope="col"
				>
					Repair
				</th>
				<th scope="col"></th>
				<th
					scope="col"
					rowspan="2"
					class="needs-repair"
				>
					Needs
					<br />
					Repair
				</th>
				<th
					scope="col"
					rowspan="2"
					class="item"
				>
					ITEM
					<br />
					BEFORE OPERATING
				</th>
				<th
					class="repair"
					scope="col"
				>
					Repair
				</th>
				<th scope="col"></th>
				<th
					scope="col"
					rowspan="2"
					class="needs-repair"
				>
					Needs
					<br />
					Repair
				</th>
				<th
					scope="col"
					rowspan="2"
					class="item"
				>
					ITEM
					<br />
					DURING WARM UP
				</th>
				<th
					class="repair"
					scope="col"
				>
					Repair
				</th>
				<th scope="col"></th>
				<th
					scope="col"
					rowspan="2"
					class="needs-repair"
				>
					Needs
					<br />
					Repair
				</th>
				<th
					scope="col"
					rowspan="2"
					class="item"
				>
					ITEM
					<br />
					DURING WARM UP
				</th>
			</tr>
			<tr>
				<th
					class="date"
					scope="col"
				>
					Date
				</th>
				<th
					scope="col"
					class="ok"
				>
					OK
				</th>
				<th
					class="date"
					scope="col"
				>
					Date
				</th>
				<th
					scope="col"
					class="ok"
				>
					OK
				</th>
				<th
					class="date"
					scope="col"
				>
					Date
				</th>
				<th
					scope="col"
					class="ok"
				>
					OK
				</th>
			</tr>
		</thead>
		<tbody>
			<tr
				class="vehicle-components-inspected-rows"
				*ngFor="let item of inspectionData.vehicleComponentsInspected"
			>
				<ng-container
					*ngIf="
						item.itemBeforeOperating.name !== 'MILEAGE BEGINNING' &&
							item.itemBeforeOperating.name !== 'MILEAGE ENDING';
						else expandedCells
					"
				>
					<!-- itemBeforeOperating -->
					<td class="date">{{ item.itemBeforeOperating.repairDate }}</td>
					<td class="mark">
						<span *ngIf="item.itemBeforeOperating.ok">√</span>
						<span *ngIf="item.itemBeforeOperating.needsRepair">x</span>
					</td>
					<td class="mark">
						<span *ngIf="item.itemBeforeOperating.needsRepair">x</span>
					</td>
					<td class="format-cell item">{{ item.itemBeforeOperating.name }}</td>

					<!-- itemDuringWarmUpA -->
					<td class="date">{{ item.itemDuringWarmUpA.repairDate }}</td>
					<td class="mark">
						<span *ngIf="item.itemDuringWarmUpA.ok">√</span>
						<span *ngIf="item.itemDuringWarmUpA.needsRepair">x</span>
					</td>
					<td class="mark">
						<span *ngIf="item.itemDuringWarmUpA.needsRepair">x</span>
					</td>
					<td class="format-cell item">{{ item.itemDuringWarmUpA.name }}</td>

					<!-- itemDuringWarmUpB -->
					<td class="date">{{ item.itemDuringWarmUpB.repairDate }}</td>
					<td class="mark">
						<span *ngIf="item.itemDuringWarmUpB.ok">√</span>
						<span *ngIf="item.itemDuringWarmUpB.needsRepair">x</span>
					</td>
					<td class="mark">
						<span *ngIf="item.itemDuringWarmUpB.needsRepair">x</span>
					</td>
					<td
						[ngClass]="{
							'item-during-warm-up-bold':
								item.itemDuringWarmUpB.name === 'HANDICAPPED' ||
								item.itemDuringWarmUpB.name === '(If Applicable)',
							handicapped: item.itemDuringWarmUpB.name === 'HANDICAPPED'
						}"
						class="format-cell item"
					>
						{{ item.itemDuringWarmUpB.name }}
					</td>
				</ng-container>

				<ng-template #expandedCells>
					<!-- itemBeforeOperating -->
					<td
						colspan="3"
						class="date"
					></td>
					<td
						[ngClass]="{
							'item-before-operating-bold':
								item.itemBeforeOperating.name === 'MILEAGE BEGINNING' ||
								item.itemBeforeOperating.name === 'MILEAGE ENDING'
						}"
						class="format-cell item"
					>
						{{ item.itemBeforeOperating.name }}
					</td>

					<!-- itemDuringWarmUpA -->
					<td class="date">{{ item.itemDuringWarmUpA.repairDate }}</td>
					<td class="mark">
						<span *ngIf="item.itemDuringWarmUpA.ok">√</span>
						<span *ngIf="item.itemDuringWarmUpA.needsRepair">x</span>
					</td>
					<td class="mark">
						<span *ngIf="item.itemDuringWarmUpA.needsRepair">x</span>
					</td>
					<td class="format-cell item">{{ item.itemDuringWarmUpA.name }}</td>

					<!-- itemDuringWarmUpB -->
					<td class="date">{{ item.itemDuringWarmUpB.repairDate }}</td>
					<td class="mark">
						<span *ngIf="item.itemDuringWarmUpB.ok">√</span>
						<span *ngIf="item.itemDuringWarmUpB.needsRepair">x</span>
					</td>
					<td class="mark">
						<span *ngIf="item.itemDuringWarmUpB.needsRepair">x</span>
					</td>
					<td
						[ngClass]="{
							'item-during-warm-up-bold':
								item.itemDuringWarmUpB.name === 'HANDICAPPED' ||
								item.itemDuringWarmUpB.name === '(If Applicable)',
							handicapped: item.itemDuringWarmUpB.name === 'HANDICAPPED'
						}"
						class="format-cell item"
					>
						{{ item.itemDuringWarmUpB.name }}
					</td>
				</ng-template>
			</tr>
		</tbody>
	</table>
</div>
