import { Component, EventEmitter, Output } from "@angular/core";

@Component({
	selector: "app-asset-filter",
	templateUrl: "./asset-filter.component.html",
	styleUrls: ["./asset-filter.component.scss"],
})
export class AssetFilterComponent {
	@Output() emitSelectedAsset = new EventEmitter();

	public onInput(event: InputEvent) {
		this.emitSelectedAsset.emit((event.currentTarget as HTMLInputElement).value);
	}
}
