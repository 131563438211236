import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { Select, Store } from "@ngxs/store";
import { PendoService } from "@zonar-ui/analytics";
import { PermissionsService } from "@zonar-ui/auth";
import { ICompany, IDivision } from "@zonar-ui/auth/lib/models/company.model";
import { IUserGroupPolicy } from "@zonar-ui/auth/lib/models/user-group-policy.model";
import { IUserProfile } from "@zonar-ui/auth/lib/models/user-profile.model";
import { ChangeCompanyService } from "@zonar-ui/sidenav";
import { Observable, Subject, combineLatest, of } from "rxjs";
import { concatMap, distinctUntilChanged, filter, take, takeUntil } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { MockSidenavMenuConfiguration } from "src/mock-data/mock-sidenav-menu-configuration";
import { isDefined } from "src/utils/isDefined/isDefined";
import { parseParamValuesFromURL } from "src/utils/parseParamValuesFromURL/parseParamValuesFromURL";
import { v4 as uuid } from "uuid";
import {
	AppState,
	GetApplicationObject,
	GetAssetTypes,
	GetAssets,
	GetConfig,
	GetDivisions,
	GetInspectionTypes,
	GetInspectors,
	GetLanguageDictionary,
	GetSettings,
	SetCompanyDivisions,
	SetCompanyHasTCU,
	SetSelectedCompany,
	SetUserDivisions,
	SetUserHasAllDivisions,
	SetUserObject,
	SetUserRoles,
} from "./app.state";
import { ErrorService } from "./components/errors/service/error.service";
import { SetNavState } from "./components/errors/state/error.state";
import { navErrorConstants } from "./constants/error-constants";
import { SetLogInfo } from "./log.state";
import { DataDogRumService } from "./services/data-dog-rum/data-dog-rum.service";
import { GlobalApiCallsService } from "./services/global-api-calls.service";
import { LoggerService } from "./services/logger.service";
import { NotificationService } from "./services/notification/notification.service";
import { PreviousPageService } from "./services/previous-page.service";

export interface RouteLink {
	label: string;
	link: string;
}

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, OnDestroy {
	private activeAccountCode: string = null;
	private activeCompany: string;
	private currentCompany: string;
	private onDestroy$ = new Subject<void>();
	private isLoading = true;

	@Select(AppState.getSelectedCompanyId) selectedCompanyId$: Observable<string>;
	@Select(AppState.selectUserDivisions) userDivisions$: Observable<Array<IDivision>>;
	@Select(AppState.selectUserHasAllDivisions) userHasAllDivisions$: Observable<boolean>;

	SIDENAV_PARAMS = {
		expanded: true,
		footer: true,
		footerOffset: false,
		hideChangeCompanyButton: false,
		hideLogoutButton: false,
		lockExpansion: false,
		mobileOpened: true,
		useDefaultHelpItem: true,
		enableContentResizing: true,
	};

	SIDENAV_MENU = MockSidenavMenuConfiguration;

	sidenavFooter: {
		items: [];
	};

	usingShadowEnvironment = environment.shadow;

	constructor(
		private changeCompanyService: ChangeCompanyService,
		private globalApiCallsService: GlobalApiCallsService,
		private pendoService: PendoService,
		private permissionsService: PermissionsService,
		public datadog: DataDogRumService,
		public errorService: ErrorService,
		public logger: LoggerService,
		public notificationService: NotificationService,
		public previousPageService: PreviousPageService,
		public router: Router,
		public store: Store,
	) {}

	ngOnInit() {
		this.store.dispatch(new SetLogInfo({ sessionId: uuid() }));
		this.getActiveAccountCode();
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
	}

	/**
	 * Dispatch the selected company to the store
	 * @param incomingCompany Current Company Context
	 */
	switchToSelectedCompany(incomingCompany: { title: string; value: string }) {
		if (!this.isLoading && incomingCompany?.value && incomingCompany.value !== this.currentCompany) {
			const companyId = incomingCompany.value;
			this.currentCompany = companyId;
			this.store.dispatch(new SetSelectedCompany(companyId));
		}
	}

	/**
	 * Get the active account code from the URL query params if any
	 */
	private getActiveAccountCode() {
		this.router.events
			.pipe(
				takeUntil(this.onDestroy$),
				concatMap((event: any, index) => {
					if (event?.url && index === 0) {
						this.activeAccountCode = parseParamValuesFromURL(event.url, "active");
						if (this.activeAccountCode) {
							this.globalApiCallsService
								.getActiveGtcCompany(
									this.activeAccountCode,
									environment.environmentConstants.APP_ENDPOINT_CORE,
								)
								.pipe(
									filter<Array<ICompany>>(company => isDefined(company?.[0]?.id)),
									distinctUntilChanged(
										([{ id: previous }], [{ id: current }]) => previous === current,
									),
									take(1),
								)
								.subscribe(([{ id }]) => {
									if (id !== this.activeCompany) {
										this.activeCompany = id;
										this.changeCompanyService.changeSelectedCompany(id);
										this.store.dispatch(new SetSelectedCompany(id));
										this.isLoading = false;
										this.initializeApp();
										this.initializePendo();
									}
								});
						} else {
							this.isLoading = false;
							this.initializeApp();
							this.initializePendo();
						}
					}
					return of(event);
				}),
			)
			.subscribe();
	}

	/**
	 * Initialize Pendo
	 */
	private initializePendo() {
		if (environment.analytics.pendo) {
			this.permissionsService
				.getIsFetchPermsSuccess()
				.pipe(
					takeUntil(this.onDestroy$),
					filter(permissions => Boolean(permissions)),
				)
				.subscribe(() => {
					this.pendoService.initialize();
				});
		}
	}

	/**
	 * Initialize the app
	 */
	private initializeApp() {
		combineLatest([
			this.permissionsService.getUser(),
			this.permissionsService.getIsZonarUser(),
			this.permissionsService.getUserProfiles(),
			this.permissionsService.getCurrentCompanyContext(),
			this.selectedCompanyId$,
		])
			.pipe(filter(this.filterUserData), distinctUntilChanged(this.distinctUntilSelectedCompanyChanged))
			.subscribe(([user, zonarUser, userProfiles, company, selectedCompanyId]) => {
				const isCompanyIdDefined = isDefined(company.id) && company.id !== "";

				/**
				 * Check if companyContextId exist (Zonar Admin Scenario) or
				 * if companyContextId is equal to selectedCompanyId (Regular User Scenario)
				 *
				 * We are making sure companyContextId === selectedCompanyId
				 * to avoid making API calls with the previous company Id
				 */
				if ((!isCompanyIdDefined && selectedCompanyId) || company.id === selectedCompanyId) {
					// Current company Id
					const currentCompany = isCompanyIdDefined ? company.id : selectedCompanyId;

					// Dispatch user data
					this.store.dispatch(new SetUserObject(user));

					combineLatest([
						this.globalApiCallsService.getCompanyLoginModeGroupPolicy(currentCompany),
						this.globalApiCallsService.getEvirUserPolicies(user.id),
					])
						.pipe(
							filter(([gpLoginMode, userPolicies]) => isDefined(gpLoginMode) && isDefined(userPolicies)),
							take(1),
						)
						.subscribe(([gpLoginMode, userPolicies]) => {
							// Filter EVIR Profiles for current company
							const evirUserProfilesByCompany = (userProfiles || []).filter(
								profile =>
									(!profile?.companyId || profile?.companyId === company.id) &&
									profile?.applicationId === environment.environmentConstants.APP_APPLICATION_ID,
							);
							// Filter EVIR Policies for current company
							const evirUserPoliciesByCompany = userPolicies.filter(policy =>
								policy.tenant.scope.companies.some(company => company.id === currentCompany),
							);

							this.getUserDivisions(
								evirUserProfilesByCompany,
								evirUserPoliciesByCompany,
								currentCompany,
								gpLoginMode,
								zonarUser,
							);
							this.getUserRoles(evirUserProfilesByCompany, evirUserPoliciesByCompany);
							this.getEVIRData();
						});
				} else {
					console.warn("Waiting on Company state to be populated");
				}
			});
	}

	/**
	 * Get the user Divisions and Company Divisions
	 * @param userProfiles User profiles
	 * @param userPolicies User group policies
	 * @param companyId Current company ID
	 * @param zonarAdmin Zonar Admin flag
	 */
	private getUserDivisions(
		userProfiles: ReadonlyArray<IUserProfile>,
		userPolicies: ReadonlyArray<IUserGroupPolicy>,
		companyId: string,
		loginModeGroupPolicy: boolean,
		zonarAdmin = false,
	) {
		this.globalApiCallsService
			.getCompanyDivision(companyId, environment.environmentConstants.APP_ENDPOINT_CORE)
			.pipe(take(1), filter(isDefined))
			.subscribe(companyDivisions => {
				this.store.dispatch(new SetCompanyDivisions(companyDivisions));
				if (!this.activeCompany || this.activeCompany === companyId) {
					if (zonarAdmin) {
						// Zonar Admin has access to all company's divisions
						this.store.dispatch(new SetUserHasAllDivisions(true));
						this.store.dispatch(new SetUserDivisions(companyDivisions));
					} else {
						if (loginModeGroupPolicy && userPolicies.length > 0) {
							// Get all user divisions from policies
							this.store.dispatch(
								new SetUserDivisions(
									this.getUserPoliciesDivisions(companyId, companyDivisions, userPolicies),
								),
							);
						} else if (!loginModeGroupPolicy && userProfiles.length > 0) {
							// Get all user divisions from profiles
							this.store.dispatch(
								new SetUserDivisions(
									this.getUserProfilesDivisions(companyId, companyDivisions, userProfiles),
								),
							);
						} else {
							// User has no user profiles or group policies with EVIR access
							this.store.dispatch(
								new SetNavState({
									http: false,
									type: navErrorConstants.NAV_ERROR_NO_ACCESS,
								}),
							);
							// Redirect user to server error page
							this.router.navigate(["/server-error"]);
						}
					}
				}
			});
	}

	/**
	 * Get User Role IDs
	 * @param userProfiles User profiles
	 * @param userPolicies User group policies
	 */
	private getUserRoles(userProfiles: ReadonlyArray<IUserProfile>, userPolicies: ReadonlyArray<IUserGroupPolicy>) {
		let userRoles: Array<string>;

		if (userProfiles.length > 0) {
			userRoles = userProfiles[0].roles.map(({ id }) => id);
		} else if (userPolicies.length > 0) {
			userRoles = userPolicies[0].policy.grants
				.filter(grant => grant.application.id === environment.environmentConstants.APP_APPLICATION_ID)
				.flatMap(({ roles }) => roles.map(role => role.id));
		}

		// Set user roles
		this.store.dispatch(new SetUserRoles(userRoles));
	}

	/**
	 * Get UserPolicies Divisions
	 * @param companyId Current company ID
	 * @param companyDivisions Array of all company divisions
	 * @param userPolicies Array of user group policies
	 * @returns Array of divisions
	 */
	private getUserPoliciesDivisions(
		companyId: string,
		companyDivisions: Array<IDivision>,
		userPolicies: ReadonlyArray<IUserGroupPolicy>,
	) {
		const companyUserPolicies = userPolicies.filter(groupPolicy =>
			groupPolicy.tenant.scope.companies.some(company => company.id === companyId),
		);
		const selectedUserGroupPolicy: IUserGroupPolicy = companyUserPolicies[0];

		const divisions =
			selectedUserGroupPolicy.tenant.scope.divisions?.length > 0
				? selectedUserGroupPolicy.tenant.scope.divisions
				: companyDivisions;

		this.store.dispatch(new SetUserHasAllDivisions(divisions.length === companyDivisions.length));

		return divisions;
	}

	/**
	 * Get UserProfiles divisions
	 * @param companyId Current company ID
	 * @param companyDivisions Array of all company divisions
	 * @param userProfiles Array of user profiles
	 * @returns Array of divisions
	 */
	private getUserProfilesDivisions(
		companyId: string,
		companyDivisions: Array<IDivision>,
		userProfiles: ReadonlyArray<IUserProfile>,
	) {
		const companyUserProfiles = userProfiles.filter(profile => profile.companyId === companyId);
		const selectedUserProfile =
			companyUserProfiles && companyUserProfiles.length ? companyUserProfiles[0] : userProfiles[0];

		const divisions =
			selectedUserProfile.divisions?.length > 0
				? companyDivisions.filter(division => selectedUserProfile.divisions.includes(division.id))
				: companyDivisions;

		this.store.dispatch(new SetUserHasAllDivisions(divisions.length === companyDivisions.length));

		return divisions;
	}

	/**
	 * Get the amount of TCU devices for the selected company
	 * @param companyId Selected Company ID
	 */
	private getCompanyTCUs(companyId: string) {
		this.globalApiCallsService
			.getCompanyTCUDevices(companyId, environment.environmentConstants.APP_ENDPOINT_CORE)
			.pipe(filter(isDefined))
			.subscribe(response => {
				const devices = parseInt(response.headers.get("x-total-count"), 10);
				this.store.dispatch(new SetCompanyHasTCU(devices > 0));
			});
	}

	/**
	 * Get EVIR Data such as configs, settings, translations and filter's data
	 */
	getEVIRData() {
		combineLatest([this.selectedCompanyId$, this.userHasAllDivisions$, this.userDivisions$])
			.pipe(
				filter(
					([companyContext, userHasAllDivisions, userDivisions]) =>
						isDefined(companyContext) && isDefined(userHasAllDivisions) && isDefined(userDivisions),
				),
				distinctUntilChanged(
					(
						[previousCompanyContext, previousUserHasAllDivisions, previousUserDivisions],
						[currentCompanyContext, currentUserHasAllDivisions, currentUserDivisions],
					) =>
						previousCompanyContext === currentCompanyContext &&
						previousUserHasAllDivisions === currentUserHasAllDivisions &&
						previousUserDivisions.length === currentUserDivisions.length,
				),
				take(1),
			)
			.subscribe(([selectedCompanyId, userHasAllDivisions, userDivisions]) => {
				const divisions = userDivisions.map(({ id }) => id);
				this.store.dispatch(
					new GetLanguageDictionary(selectedCompanyId, environment.environmentConstants.APP_ENDPOINT_EVIR),
				);
				this.store.dispatch(
					new GetSettings(selectedCompanyId, environment.environmentConstants.APP_ENDPOINT_EVIR),
				);
				this.store.dispatch(
					new GetConfig(selectedCompanyId, environment.environmentConstants.APP_ENDPOINT_EVIR),
				);
				this.store.dispatch(
					new GetInspectionTypes(selectedCompanyId, environment.environmentConstants.APP_ENDPOINT_EVIR),
				);
				this.store.dispatch(
					new GetAssetTypes(selectedCompanyId, environment.environmentConstants.APP_ENDPOINT_EVIR),
				);
				this.store.dispatch(
					new GetAssets(
						selectedCompanyId,
						environment.environmentConstants.APP_ENDPOINT_EVIR,
						userHasAllDivisions,
						divisions,
					),
				);
				this.store.dispatch(
					new GetInspectors(
						selectedCompanyId,
						environment.environmentConstants.APP_ENDPOINT_EVIR,
						userHasAllDivisions,
						divisions,
					),
				);
				this.store.dispatch(
					new GetDivisions(
						selectedCompanyId,
						environment.environmentConstants.APP_ENDPOINT_CORE,
						userHasAllDivisions,
						divisions,
					),
				);
				this.store.dispatch(
					new GetApplicationObject(
						environment.environmentConstants.APP_APPLICATION_ID,
						environment.environmentConstants.APP_ENDPOINT_CORE,
					),
				);
				this.getCompanyTCUs(selectedCompanyId);
			});
	}

	/**
	 * Filter defined values
	 * @param param0 Array of values
	 * @returns Boolean
	 */
	private filterUserData([user, zonarUser, userProfiles, company, selectedCompanyId]) {
		return (
			Boolean(user) &&
			isDefined(zonarUser) &&
			Boolean(userProfiles) &&
			Boolean(company) &&
			Boolean(selectedCompanyId)
		);
	}

	/**
	 * Distinct Selected Company until changed
	 * @param param0 Array of previous values
	 * @param param1 Array of current values
	 * @returns Boolean
	 */
	private distinctUntilSelectedCompanyChanged(
		[_prevU, _preZU, _prevUP, prevCompanyContext, prevSelectedCompanyId],
		[_currentU, _currentZU, _currentUP, currentCompanyContext, currentSelectedCompanyId],
	) {
		return prevCompanyContext === currentCompanyContext && prevSelectedCompanyId === currentSelectedCompanyId;
	}
}
