import { Routes } from "@angular/router";
import { AuthGuard } from "@auth0/auth0-angular";
import { PermissionsGuard } from "@zonar-ui/auth";
import { AssetDetailsComponent } from "../../../views/asset-details/asset-details.component";
import { DefectListComponent } from "../../../views/defect-list/defect-list.component";
import { InspectionDetailComponent } from "../../../views/inspection-detail/inspection-detail.component";
import { InspectionHistoryComponent } from "../../../views/inspection-history/inspection-history.component";
import { InspectionsPrintViewComponent } from "../../inspections-print-view/inspections-print-view.component";
import { MissingInspectionsComponent } from "../../missing-inspections/missing-inspections.component";
import { SettingsComponent } from "../../settings/settings.component";
import { NjmvcDriversVehicleInspectionReportComponent } from "../../njmvc-drivers-vehicle-inspection-report/njmvc-drivers-vehicle-inspection-report.component";

export const DEFAULT_ROUTES: Routes = [
	{ path: "", redirectTo: "inspection-list", pathMatch: "full" },
	{ path: "inspection-list", component: InspectionHistoryComponent, canActivate: [AuthGuard, PermissionsGuard] },
	{
		path: "inspections-print-view",
		component: InspectionsPrintViewComponent,
		canActivate: [AuthGuard, PermissionsGuard],
	},
	{
		path: "njmvc-drivers-vehicle-inspection-report",
		component: NjmvcDriversVehicleInspectionReportComponent,
		canActivate: [AuthGuard, PermissionsGuard],
	},
	{ path: "settings", component: SettingsComponent, canActivate: [AuthGuard, PermissionsGuard] },
	{
		path: "company/:company-uuid/inspection/:inspection-uuid",
		component: InspectionDetailComponent,
		canActivate: [AuthGuard, PermissionsGuard],
	},
	{ path: "defects", component: DefectListComponent, canActivate: [AuthGuard, PermissionsGuard] },
	{ path: "missing-inspections", component: MissingInspectionsComponent, canActivate: [AuthGuard, PermissionsGuard] },
	{
		path: "company/:company-uuid/asset-details/:asset-uuid",
		component: AssetDetailsComponent,
		canActivate: [AuthGuard, PermissionsGuard],
	},
];
