import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { tap } from "rxjs/operators";
import { InspectionListPrintViewModel } from "src/app/models/inspection-print-view.models";
import { GlobalApiCallsService } from "src/app/services/global-api-calls.service";
import { BQInspection, InspectionGet } from "../../../models/openAPIAliases";
import { InspectionHistoryService } from "../service/inspection-history.service";

export class InspectionHistoryStateModel {
	inspectionList: InspectionGet[];
	inspectionListCount: number;
	inspectionListForPdfExport: InspectionGet[];
	inspectionsPrintViewViewModels: InspectionListPrintViewModel[];
	njmvcInspections: ReadonlyArray<BQInspection>;
}

const inspectionHistoryStateDefaults: InspectionHistoryStateModel = {
	inspectionList: null,
	inspectionListCount: 0,
	inspectionListForPdfExport: [],
	inspectionsPrintViewViewModels: [],
	njmvcInspections: undefined,
};

export class GetInspectionListWithQueryStrings {
	static readonly type = "[Inspection List View] Get Inspection List With Query Strings";
	constructor(public queryStrings: URLSearchParams, public companyId: string, public endpoint: string) {}
}

export class GetInspectionListForPdfExport {
	static readonly type = "[Inspection List View] Get Inspection List for PDF export";
	constructor(public queryStrings: URLSearchParams, public companyId: string, public endpoint: string) {}
}

export class GetNJMVCInspections {
	static readonly type = "[Inspection List View] Get NJMVC Inspections";
	constructor(
		public companyId: string,
		public data: { startDate: Date; endDate: Date; assetName: string; vehicleType: string },
	) {}
}

export class ResetNJMVCInspections {
	static readonly type = "[Inspection List View] Reset NJMVC Inspections";
}

export class PopulateInspectionsPrintViewViewModels {
	static readonly type = "[Inspection List View] Put the view models for the print view in the store";
	constructor(public viewModels: InspectionListPrintViewModel[]) {}
}
@Injectable()
@State<InspectionHistoryStateModel>({
	name: "inspectionList",
	defaults: inspectionHistoryStateDefaults,
})
export class InspectionHistoryState {
	constructor(
		private inspectionHistoryService: InspectionHistoryService,
		private globalApiCallsService: GlobalApiCallsService,
	) {}

	@Selector()
	static getInspectionList(state: InspectionHistoryStateModel) {
		return state.inspectionList;
	}

	@Selector()
	static getInspectionListCount(state: InspectionHistoryStateModel) {
		return state.inspectionListCount;
	}

	@Selector()
	static getInspectionListForPdfExport(state: InspectionHistoryStateModel) {
		return state.inspectionListForPdfExport;
	}

	@Selector()
	static getInspectionsPrintViewViewModels(state: InspectionHistoryStateModel) {
		return state.inspectionsPrintViewViewModels;
	}

	@Selector()
	static getNJMVCInspections(state: InspectionHistoryStateModel) {
		return state.njmvcInspections;
	}

	@Action(GetInspectionListWithQueryStrings)
	getInspectionListWithQueryStrings(
		{ patchState }: StateContext<InspectionHistoryStateModel>,
		{ queryStrings, companyId, endpoint }: GetInspectionListWithQueryStrings,
	) {
		return this.inspectionHistoryService.getInspectionList(companyId, endpoint, queryStrings).pipe(
			tap((response: HttpResponse<InspectionGet[]>) => {
				patchState({
					inspectionList: response.body,
					inspectionListCount: parseInt(response.headers.get("x-total-count"), 10),
				});
			}),
		);
	}

	@Action(GetInspectionListForPdfExport)
	getInspectionListForPdfExport(
		{ patchState }: StateContext<InspectionHistoryStateModel>,
		{ queryStrings, companyId, endpoint }: GetInspectionListForPdfExport,
	) {
		return this.inspectionHistoryService.getInspectionList(companyId, endpoint, queryStrings).pipe(
			tap((response: HttpResponse<InspectionGet[]>) => {
				patchState({
					inspectionListForPdfExport: response.body,
				});
			}),
		);
	}

	@Action(PopulateInspectionsPrintViewViewModels)
	populateInspectionsPrintViewViewModels(
		{ patchState }: StateContext<InspectionHistoryStateModel>,
		{ viewModels }: PopulateInspectionsPrintViewViewModels,
	) {
		patchState({
			inspectionsPrintViewViewModels: viewModels,
		});
	}

	@Action(GetNJMVCInspections)
	getNJMVCInspections(
		{ patchState }: StateContext<InspectionHistoryStateModel>,
		{ companyId, data }: GetNJMVCInspections,
	) {
		return this.globalApiCallsService.getNJMVCInspectionData(companyId, data).pipe(
			tap(response => {
				patchState({
					njmvcInspections: response,
				});
			}),
		);
	}
	@Action(ResetNJMVCInspections)
	resetNJMVCInspections({ patchState }: StateContext<InspectionHistoryStateModel>) {
		return patchState({
			njmvcInspections: undefined,
		});
	}
}
