export const displayValueFields = {
	assets: ["assetName"],
	divisions: ["divisionName"],
	inspectors: ["inspectorFirstName", "inspectorLastName"],
} as const;

type DisplayValueFields = typeof displayValueFields;
type DisplayValueFieldsKey = keyof DisplayValueFields;
export type SingleRecord = {
	[Key in DisplayValueFieldsKey]: Record<DisplayValueFields[Key][number], unknown>;
}[DisplayValueFieldsKey];

export const getDisplayValue = (record: SingleRecord, tableName: keyof typeof displayValueFields): string =>
	displayValueFields[tableName].map(field => record[field]).join(" ");
