import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { VehicleType } from "src/app/models/VehicleType";

@Component({
	selector: "app-vehicle-type-filter",
	templateUrl: "./vehicle-type-filter.component.html",
	styleUrls: ["./vehicle-type-filter.component.scss"],
})
export class VehicleTypeFilterComponent implements OnInit {
	@Input() context: string;
	@Input() options: ReadonlyArray<string> = ["A", "B", "C", "D", "SV"];
	@Output() emitVehicleType = new EventEmitter();

	buttonIconValue = "arrow_drop_down";
	dropdownLabel = "Vehicle Type";
	filterButtonClass = "filter-button-not-focused";
	filterButtonLabelClass = "filter-button-label-not-focused";
	filterButtonTextClass = "filter-button-text-value-not-selected";
	filterButtonTextMaxCharacters = 10;
	selectedVehicleType: VehicleType;
	filterButtonText: string;
	showLabel: boolean;

	ngOnInit(): void {
		this.showLabel = false;
		this.filterButtonText = "Vehicle Type";
	}

	emitVehicleTypeSelectedOption(vehicleType: VehicleType) {
		this.selectedVehicleType = vehicleType;
		this.emitVehicleType.emit(vehicleType);
	}

	handleMenuClose(): void {
		const valueSelected = this.selectedVehicleType === undefined;
		this.buttonIconValue = "arrow_drop_down";
		this.filterButtonClass = "filter-button-not-focused";
		this.filterButtonLabelClass = "filter-button-label-not-focused";
		this.filterButtonTextClass = valueSelected
			? "filter-button-text-value-not-selected"
			: "filter-button-text-value-selected";
		this.filterButtonText = valueSelected ? "Vehicle Type" : this.selectedVehicleType;
		this.showLabel = !valueSelected;
	}

	handleMenuOpen(): void {
		this.buttonIconValue = "arrow_drop_up";
		this.filterButtonClass = "filter-button-focused";
		this.filterButtonLabelClass = "filter-button-label-focused";
		this.filterButtonText = this.selectedVehicleType === undefined ? "" : this.selectedVehicleType;
		this.showLabel = true;
	}
}
