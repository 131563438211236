<form class="asset-form">
	<mat-form-field
		class="asset-form-field-element"
		appearance="outline"
	>
		<mat-label>Enter an asset name</mat-label>
		<input
			matInput
			#input
			(input)="onInput($event)"
		/>
	</mat-form-field>
</form>
