import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Select } from "@ngxs/store";
import { startOfDay, subDays } from "date-fns";
import { Observable } from "rxjs";
import { filter } from "rxjs/operators";
import { AppState } from "src/app/app.state";
import { DateRangeFilterChange } from "src/app/models/emitter-events.models";
import { Asset } from "src/app/models/openAPIAliases";
import { VehicleType } from "src/app/models/VehicleType";
import { MemCacheService } from "src/app/services/mem-cache/mem-cache.service";
import { isDefined } from "src/utils/isDefined/isDefined";
import { newDate } from "src/utils/newDate/newDate";

@Component({
	selector: "app-njmvc-export-dialog",
	templateUrl: "./njmvc-export-dialog.component.html",
	styleUrls: ["./njmvc-export-dialog.component.scss"],
})
export class NjmvcExportDialogComponent implements OnInit {
	currentStartDate: Date = startOfDay(subDays(newDate(), 7));
	currentEndDate: Date = newDate();
	currentVehicleType: VehicleType;
	currentAssetName: string;
	companyAddress: string;
	assets: ReadonlyArray<Asset>;
	error = false;
	stateKey = "njmvc-report";

	@Select(AppState.selectAssets) assets$: Observable<Array<Asset>>;

	constructor(
		public dialogRef: MatDialogRef<NjmvcExportDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: { error: boolean },
		private memCacheService: MemCacheService,
	) {}

	ngOnInit(): void {
		this.assets$.pipe(filter(isDefined)).subscribe(assets => {
			this.assets = assets.sort((a, b) => a.assetName.localeCompare(b.assetName));
		});
		this.error = this.data.error;

		this.initState();
	}

	initState() {
		const state: { dateRangeBeginDate: Date; dateRangeEndDate: Date } = this.memCacheService.getValue<{
			dateRangeBeginDate: Date;
			dateRangeEndDate: Date;
		}>(`date-range-filter: ${this.stateKey}`) ?? {
			dateRangeBeginDate: this.currentStartDate,
			dateRangeEndDate: this.currentEndDate,
		};
		this.currentStartDate = state.dateRangeBeginDate;
		this.currentEndDate = state.dateRangeEndDate;
	}

	public handleDateRangeSelection(dateEvent: DateRangeFilterChange) {
		this.currentStartDate = dateEvent.beginDate;
		this.currentEndDate = dateEvent.endDate;
	}

	public handleCompanyAddressInput(address: string) {
		this.companyAddress = address;
	}

	public handleAssetSelection(assetName: string) {
		this.currentAssetName = assetName;
	}

	public handledVehicleTypeSelection(vehicleType: VehicleType) {
		this.currentVehicleType = vehicleType;
	}

	public closeDialog(eventType: "close" | "submit"): void {
		if (eventType === "submit") {
			const emittedData = {
				startDate: this.currentStartDate,
				endDate: this.currentEndDate,
				companyAddress: this.companyAddress,
				assetName: this.currentAssetName,
				vehicleType: this.currentVehicleType,
			};

			this.dialogRef.close(emittedData);
		} else {
			this.dialogRef.close();
		}
	}

	get submitNotEnabled() {
		return (
			this.currentStartDate === undefined ||
			this.currentEndDate === undefined ||
			this.companyAddress === undefined ||
			this.currentVehicleType === undefined ||
			this.currentAssetName === undefined
		);
	}
}
