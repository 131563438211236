<button
	mat-button
	class="filter-button"
	[ngClass]="filterButtonClass"
	[matMenuTriggerFor]="vehicleTypeMenu"
	(menuOpened)="handleMenuOpen()"
>
	<span
		*ngIf="showLabel"
		class="filter-button-label"
		[ngClass]="filterButtonLabelClass"
	>
		{{ dropdownLabel }}
	</span>
	<span
		class="filter-button-text"
		[ngClass]="filterButtonTextClass"
	>
		{{ filterButtonText }}
	</span>
	<mat-icon>{{ buttonIconValue }}</mat-icon>
</button>
<mat-menu
	#vehicleTypeMenu="matMenu"
	xPosition="after"
	class="vehicle-type-menu"
	(closed)="handleMenuClose()"
>
	<button
		mat-menu-item
		class="vehicle-type-option-button"
		[ngClass]="{ 'vehicle-type-option-selected': selectedVehicleType === 'A' }"
		(click)="emitVehicleTypeSelectedOption('A')"
	>
		A
	</button>
	<button
		mat-menu-item
		class="vehicle-type-option-button"
		[ngClass]="{ 'vehicle-type-option-selected': selectedVehicleType === 'B' }"
		(click)="emitVehicleTypeSelectedOption('B')"
	>
		B
	</button>
	<button
		mat-menu-item
		class="vehicle-type-option-button"
		[ngClass]="{ 'vehicle-type-option-selected': selectedVehicleType === 'C' }"
		(click)="emitVehicleTypeSelectedOption('C')"
	>
		C
	</button>
	<button
		mat-menu-item
		class="vehicle-type-option-button"
		[ngClass]="{ 'vehicle-type-option-selected': selectedVehicleType === 'D' }"
		(click)="emitVehicleTypeSelectedOption('D')"
	>
		D
	</button>
	<button
		mat-menu-item
		class="vehicle-type-option-button"
		[ngClass]="{ 'vehicle-type-option-selected': selectedVehicleType === 'SV' }"
		(click)="emitVehicleTypeSelectedOption('SV')"
	>
		SV
	</button>
</mat-menu>
