export const itemsDuringWarmUpA = [
	"Service Brakes",
	"Low Air Warning",
	"Parking Brake",
	"Fuel Gauge",
	"AMP Meter",
	"Oil Pressure",
	"Horn",
	"Heaters / Defrosters",
	"Windshield Wipers",
	"Windshield Washers",
	"Headlights",
	"Tail Lights",
	"Stop Lights",
	"Brake Light Indicator",
	"Marker / Clearance Lights",
	"Directional Signals / 4-Way",
	"Reflectors",
	"Mirrors",
	"",
	"",
	"",
	"",
];
