<div
	mat-dialog-title
	fxLayout="row"
	fxLayoutAlign="space-between start"
>
	<div class="title">NJMVC Drivers Vehicle Inspection Report</div>
	<img
		id="clear"
		class="pointer"
		src="assets/ic_clear.svg"
		(click)="closeDialog('close')"
	/>
</div>

<span class="warning">Generating this report might take up some time depending on the date range requested.</span>

<div class="filters">
	<div>
		<app-date-range-filter
			(submitDateRangeFilter)="handleDateRangeSelection($event)"
			[initStartDate]="currentStartDate"
			[initEndDate]="currentEndDate"
			[context]="stateKey"
		></app-date-range-filter>

		<app-company-address-input (emitCompanyAddress)="handleCompanyAddressInput($event)"></app-company-address-input>
	</div>
	<div>
		<app-asset-filter (emitSelectedAsset)="handleAssetSelection($event)"></app-asset-filter>

		<app-vehicle-type-filter (emitVehicleType)="handledVehicleTypeSelection($event)"></app-vehicle-type-filter>
	</div>
</div>

<div
	class="error"
	*ngIf="error"
>
	<span>No inspections found. Please try exporting another asset's inspections or enter a different date.</span>
</div>

<div
	mat-dialog-actions
	align="end"
>
	<app-action-button
		[disabled]="submitNotEnabled"
		title="Export inspections"
		(buttonClicked)="closeDialog('submit')"
		[format]="'title'"
	></app-action-button>
</div>
