<ng-container *ngIf="viewModels; then printView; else backButton"></ng-container>

<ng-template #backButton>
	<div class="back-button">
		<a
			[routerLink]="previousPageUrl"
			class="inspection-history-link"
		>
			<
			{{ "back to previousPageTitle" | i18next: { format: "capitalize", previousPageTitle: previousPageTitle } }}
		</a>
	</div>
</ng-template>

<ng-template #printView>
	<div class="back-button">
		<a
			[routerLink]="previousPageUrl"
			class="inspection-history-link"
		>
			<
			{{ "back to previousPageTitle" | i18next: { format: "capitalize", previousPageTitle: previousPageTitle } }}
		</a>
	</div>

	<ng-container *ngFor="let inspection of viewModels">
		<app-single-njmvc-drivers-vehicle-inspection-report
			[inspectionData]="inspection"
		></app-single-njmvc-drivers-vehicle-inspection-report>
	</ng-container>
</ng-template>
