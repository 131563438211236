import { Component, Input } from "@angular/core";
import { NJMVCInspection } from "src/app/models/NJMVCInspection";

@Component({
	selector: "app-single-njmvc-drivers-vehicle-inspection-report",
	templateUrl: "./single-njmvc-drivers-vehicle-inspection-report.component.html",
	styleUrls: ["./single-njmvc-drivers-vehicle-inspection-report.component.scss"],
})
export class SingleNjmvcDriversVehicleInspectionReportComponent {
	@Input() inspectionData: NJMVCInspection;

	get vehicleType() {
		return this.inspectionData.vehicleType;
	}
}
