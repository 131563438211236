import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Select, Store } from "@ngxs/store";
import { combineLatest, Observable, Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { AppState } from "src/app/app.state";
import { itemsBeforeOperating } from "src/app/constants/itemsBeforeOperating";
import { itemsDuringWarmUpA } from "src/app/constants/itemsDuringWarmUpA";
import { itemsDuringWarmUpB } from "src/app/constants/itemsDuringWarmUpB";
import { NJMVCInspection } from "src/app/models/NJMVCInspection";
import { BQDefect, BQInspection } from "src/app/models/openAPIAliases";
import { VehicleComponentsInspected } from "src/app/models/VehicleComponentsInspected";
import { VehicleComponentsInspectedItem } from "src/app/models/VehicleComponentsInspectedItem";
import { VehicleType } from "src/app/models/VehicleType";
import { isDefined } from "src/utils/isDefined/isDefined";
import { newDate } from "src/utils/newDate/newDate";
import { formatDate } from "../../i18next/formatDate";
import { InspectionHistoryState, ResetNJMVCInspections } from "../inspection-history/state/inspection-history.state";

@Component({
	selector: "app-njmvc-drivers-vehicle-inspection-report",
	templateUrl: "./njmvc-drivers-vehicle-inspection-report.component.html",
	styleUrls: ["./njmvc-drivers-vehicle-inspection-report.component.scss"],
})
export class NjmvcDriversVehicleInspectionReportComponent implements OnInit, OnDestroy {
	companyId: string;
	previousPageTitle = "Inspection List";
	previousPageUrl = "/inspection-list";
	vehicleType: VehicleType = "SV";
	viewModels: ReadonlyArray<NJMVCInspection>;
	private onDestroy$ = new Subject<void>();

	@Select(AppState.getSelectedCompanyId) selectedCompanyId$: Observable<string>;
	@Select(AppState.selectCompanyAddress) companyAddress$: Observable<string>;
	@Select(InspectionHistoryState.getNJMVCInspections) njmvcInspections$: Observable<ReadonlyArray<BQInspection>>;

	constructor(private router: Router, public store: Store) {}

	ngOnInit(): void {
		this.selectedCompanyId$.pipe(filter(isDefined)).subscribe(selectedCompanyId => {
			if (this.companyId) {
				this.router.navigate(["/inspection-list"]);
			} else {
				this.companyId = selectedCompanyId;

				// Add data-agent attribute to html
				document.documentElement.dataset.agent = navigator.userAgent;
				// Get data
				combineLatest([this.njmvcInspections$, this.companyAddress$])
					.pipe(
						filter(
							([inspections, companyAddress]) =>
								isDefined(companyAddress) && isDefined(inspections) && (inspections?.length ?? 0) > 0,
						),
						takeUntil(this.onDestroy$),
					)
					.subscribe(([inspections, companyAddress]) => {
						if ((inspections?.length ?? 0) > 0) {
							const sortedInspections = [...inspections].sort((a, b) => {
								const dateA = new Date(a.start_time).getTime();
								const dateB = new Date(b.start_time).getTime();
								return dateB - dateA;
							});
							this.viewModels = this.transformData(sortedInspections, companyAddress);
						}
					});
			}
		});
	}

	clickedOnBack() {
		this.router.navigate([this.previousPageUrl]);
	}

	transformData(inspections: ReadonlyArray<BQInspection>, companyAddress: string): ReadonlyArray<NJMVCInspection> {
		return inspections.map(inspection => ({
			inspectionDate: formatDate(newDate(inspection.start_time), "P"),
			inspectionTime: formatDate(newDate(inspection.start_time), "pp"),
			fleetUnitNumber: inspection.assets[0].details.number,
			operator: inspection.account.name,
			driverName: `${inspection.inspector.details.first_name} ${inspection.inspector.details.last_name}`,
			licensePlateNumber: inspection.assets[0].details.license_number,
			address: companyAddress,
			driverNameSigned: `${inspection.inspector.details.first_name} ${inspection.inspector.details.last_name} — E-signature`,
			vehicleType: inspection.vehicle_type,
			vehicleComponentsInspected: this.generateVehicleComponentsInspectedList(inspection),
		}));
	}

	generateVehicleComponentsInspectedList(inspection: BQInspection) {
		let inspectionComponents = this.createBaseDataTemplate(
			inspection.mileage,
			inspection.config_name,
			inspection.handicapped,
		);

		inspection.assets[0].zones.map(({ defects }) => {
			defects.map(defect => {
				inspectionComponents = inspectionComponents.map((component, index) => ({
					itemBeforeOperating: this.generateItem(
						defect,
						component.itemBeforeOperating,
						itemsBeforeOperating[index],
						index !== 19 && index !== 21,
					),
					itemDuringWarmUpA: this.generateItem(
						defect,
						component.itemDuringWarmUpA,
						itemsDuringWarmUpA[index],
						index < 19,
					),
					itemDuringWarmUpB: this.generateItem(
						defect,
						component.itemDuringWarmUpB,
						itemsDuringWarmUpB[index],
						inspection.handicapped
							? itemsDuringWarmUpB[index] !== "(If Applicable)"
							: itemsDuringWarmUpB[index] !== "HANDICAPPED" &&
									itemsDuringWarmUpB[index] !== "(If Applicable)",
					),
				}));
			});
		});

		return inspectionComponents;
	}

	private generateItem(
		{ nj_component, repair_date }: BQDefect,
		item: VehicleComponentsInspectedItem,
		name: string,
		shouldUpdate = false,
	) {
		const needsRepair = name.toLocaleLowerCase() === nj_component.toLocaleLowerCase();

		return shouldUpdate && needsRepair
			? {
					...item,
					name,
					ok: !needsRepair,
					needsRepair,
					repairDate: repair_date ? formatDate(newDate(repair_date), "P") : "",
			  }
			: item;
	}

	private createBaseDataTemplate(
		mileage: number,
		inspectionType: string,
		handicapped: boolean,
	): VehicleComponentsInspected {
		return new Array(22)
			.fill({
				itemBeforeOperating: {
					name: undefined,
					needsRepair: false,
					ok: true,
					repairDate: undefined,
				},
				itemDuringWarmUpA: {
					name: undefined,
					needsRepair: false,
					ok: true,
					repairDate: undefined,
				},
				itemDuringWarmUpB: {
					name: undefined,
					needsRepair: false,
					ok: true,
					repairDate: undefined,
				},
			})
			.map((component, index) => {
				const indexToRenderMilage = inspectionType.toLocaleLowerCase().includes("post") ? 21 : 19;
				return {
					itemBeforeOperating: {
						...component.itemBeforeOperating,
						name: index === indexToRenderMilage ? `${mileage}` : itemsBeforeOperating[index],
						ok: index !== 19 && index !== 21, // Do not mark as ok rows for Milage
					},
					itemDuringWarmUpA: {
						...component.itemDuringWarmUpA,
						name: itemsDuringWarmUpA[index],
						ok: itemsDuringWarmUpA[index] !== "", // Do not mark as ok rows with no data
					},
					itemDuringWarmUpB: {
						...component.itemDuringWarmUpB,
						name: itemsDuringWarmUpB[index],
						ok: handicapped
							? itemsDuringWarmUpB[index] !== "(If Applicable)"
							: itemsDuringWarmUpB[index] !== "HANDICAPPED" &&
							  itemsDuringWarmUpB[index] !== "(If Applicable)", // Do not mark as ok rows with HANDICAPPED and (If Applicable)
					},
				};
			});
	}

	ngOnDestroy() {
		this.store.dispatch(new ResetNJMVCInspections());
		this.onDestroy$.next();
		this.onDestroy$.complete();
	}
}
